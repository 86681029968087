import React, { useState } from 'react';

import { Button, Text, useToast, Heading, Flex } from '@chakra-ui/react';
import { add, format, set } from 'date-fns';
import { saveAs } from 'file-saver';
import { DatePicker } from '../../components/common/datepicker/datepicker';
import { useDownloadComenziPieseHar } from '../../state/server-side/queries/rapoarte';

export const ComenziPieseHarPage = () => {
	const toast = useToast();
	const today = set(new Date(), { hours: 0, minutes: 0, milliseconds: 0 });
	const [startDate, setStartDate] = useState(add(today, { months: -1 }));
	const [endDate, setEndDate] = useState(today);

	const download = useDownloadComenziPieseHar();

	const handleSubmit = () => {
		download.mutate(
			{ startDate: format(startDate, 'yyyyMMdd'), endDate: format(endDate, 'yyyyMMdd') },
			{
				onSuccess: data => {
					saveAs(data, `${format(new Date(), `yyyy-MM-dd'T'HH-mm-ss`)}_comenzi-piese-HAR.xlsx`);
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	};

	return (
		<>
			<Heading as={'h2'} size={'lg'} mb={8}>
				Comenzi piese HAR
			</Heading>
			<Flex gap={4}>
				<div>
					<Text>Incepand cu:</Text>
					<DatePicker
						selected={startDate}
						onChange={date => setStartDate(date)}
						selectsStart
						startDate={startDate}
						endDate={endDate}
					/>
				</div>
				<div>
					<Text>Pana la:</Text>
					<DatePicker
						selected={endDate}
						onChange={date => setEndDate(date)}
						selectsEnd
						startDate={startDate}
						endDate={endDate}
						minDate={startDate}
					/>
				</div>
			</Flex>
			<Button mt={4} alignSelf={'flex-start'} onClick={handleSubmit}>
				Genereaza raport
			</Button>
		</>
	);
};
