import { useMutation } from 'react-query';
import { getAuth } from '@firebase/auth';

const { REACT_APP_API_URL: API_URL } = process.env;

const downloadComenziPieseHar = async (startDate, endDate) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/rapoarte/comenzi-piese-har?startDate=${startDate}&endDate=${endDate}`, {
		headers: { authorization: `Bearer ${token}` },
	});

	if (!res.ok) {
		const error = await res.json();
		throw new Error(error.error);
	}

	return await res.blob();
};

export const useDownloadComenziPieseHar = () => {
	return useMutation(({ startDate, endDate }) => downloadComenziPieseHar(startDate, endDate));
};

const downloadStatusCampanie3AniHar = async (startDate, endDate) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/rapoarte/status-campanie-3ani-har?startDate=${startDate}&endDate=${endDate}`, {
		headers: { authorization: `Bearer ${token}` },
	});

	if (!res.ok) {
		const error = await res.json();
		throw new Error(error.error);
	}

	return await res.blob();
};

export const useDownloadStatusCampanie3AniHar = () => {
	return useMutation(({ startDate, endDate }) => downloadStatusCampanie3AniHar(startDate, endDate));
};
