import React, { useState } from 'react';

import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { add, format, set } from 'date-fns';
import { useDownloadLabStats } from '../../state/server-side/queries/laborator';
import { saveAs } from 'file-saver';
import { DatePicker } from '../common/datepicker/datepicker';

const LaboratorDatepicker = () => {
	const toast = useToast();
	const today = set(new Date(), { hours: 0, minutes: 0, milliseconds: 0 });
	const [startDate, setStartDate] = useState(add(today, { months: -1 }));
	const [endDate, setEndDate] = useState(today);

	const download = useDownloadLabStats();

	const handleSubmit = () => {
		download.mutate(
			{ startDate: format(startDate, 'yyyyMMdd'), endDate: format(endDate, 'yyyyMMdd') },
			{
				onSuccess: data => {
					saveAs(data, 'test.xlsx');
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	};

	return (
		<Box>
			<Text>Incepand cu:</Text>
			<DatePicker
				selected={startDate}
				onChange={date => setStartDate(date)}
				selectsStart
				startDate={startDate}
				endDate={endDate}
			/>
			<Text mt={4}>Pana la:</Text>
			<DatePicker
				selected={endDate}
				onChange={date => setEndDate(date)}
				selectsEnd
				startDate={startDate}
				endDate={endDate}
				minDate={startDate}
			/>
			<Button mt={4} onClick={handleSubmit}>
				Download Excel
			</Button>
		</Box>
	);
};

export default LaboratorDatepicker;
