import { Button, Flex } from '@chakra-ui/react';
import { Outlet, useNavigate, Navigate } from 'react-router-dom';
import { canSeeReports, isAdmin } from '../../utils/permissions';
import { useAuthState } from '../../utils/firebase';
import BackButton from '../../components/common/basic/back-button';

export const RapoartePage = () => {
	const { user } = useAuthState();
	const navigate = useNavigate();

	const handleNavigate = route => {
		navigate(route);
	};

	const handleBack = () => {
		navigate('/');
	};

	if (!canSeeReports(user)) {
		return <Navigate to={'/'} />;
	}

	return (
		<Flex w={'100%'} h={'calc(100vh - 64px)'} borderTop={'1px'} borderColor={'gray.700'}>
			<Flex direction={'column'} w={'250px'} borderRight={'1px'} borderColor={'gray.700'}>
				<Button
					justifyContent={'flex-start'}
					variant={'ghost'}
					colorScheme={'blue'}
					mt={1}
					rounded={'none'}
					isFullWidth
					onClick={() => handleNavigate('/rapoarte/comenzi-piese-har')}
				>
					Comenzi piese HAR
				</Button>
				{isAdmin(user) ? (
					<Button
						justifyContent={'flex-start'}
						variant={'ghost'}
						colorScheme={'blue'}
						mt={1}
						rounded={'none'}
						isFullWidth
						onClick={() => handleNavigate('/rapoarte/status-campanie-3ani-har')}
					>
						Status campanie 3 ani HAR
					</Button>
				) : null}
				<BackButton
					justifyContent={'flex-start'}
					rounded={'none'}
					size={'md'}
					colorScheme={'red'}
					onClick={handleBack}
				>
					Inapoi la aplicatie
				</BackButton>
			</Flex>
			<Flex flexGrow={1} direction={'column'} py={6} px={6} overflowY={'auto'} height={'100%'}>
				<Outlet />
			</Flex>
		</Flex>
	);
};
