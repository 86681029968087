import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

export const DatePicker = ({ selectedDate, onChange, isClearable = false, showPopperArrow = false, ...props }) => {
	return (
		// if you don't want to use chakra's colors or you just wwant to use the original ones,
		// set className to "light-theme-original" ↓↓↓↓
		<div className={'dark-theme'}>
			<ReactDatePicker
				selected={selectedDate}
				onChange={onChange}
				isClearable={isClearable}
				showPopperArrow={showPopperArrow}
				dateFormat={'dd.MM.yyyy'}
				className="react-datapicker__input-text" //input is white by default and there is no already defined class for it so I created a new one
				calendarStartDay={1}
				{...props}
			/>
		</div>
	);
};
